<template>
<div class="flex">
    <router-link
      class="flex items-center w-full px-4 py-2 mr-2 bg-white hover:shadow-md"
      :to="{ path: report.path }"
      :props="report.props"
    >
      <font-awesome-icon :icon="['far', icon]" />
      <div class="ml-2">{{ report.text }}</div>
    </router-link>
</div>
</template>

<script>
  export default {
    name: "ReportLink",
    props: {
      report: {
        type: Object,
        default: () => {} 
      },
    },
    computed: {
      icon () {
        return this.report.icon != null ? this.report.icon : 'table'
      }
    },
  }
</script>

<style lang="scss" scoped>
</style>